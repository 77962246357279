import _ from 'lodash'
import { defineAsyncComponent } from 'vue'
///* <vue2>
import JsonView from 'vue-json-viewer'
import VueSelect from 'vue-select'
import Teleport from 'vue2-teleport'
// </vue2> */
import OrderEditComponents from '@/pages/Orders/New/components.js'
import QaqcQueueComponents from '@/pages/Qaqc/Queue/components.js'
import Table from '../common/table/components.js'

// globl components
window.PopupImportCsvShort = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Orders/popup-import-csv-short.vue'))
window.PopupMapOptimizeRoute = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Map/popup-map-optimize-route.vue'))
window.PopupMapOptimizeRoutes = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Map/popup-map-optimize-routes.vue'))
window.PopupImportPrimerxPDF = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Orders/popup-import-primerx-pdf.vue'))
window.PopupAddStaff = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Users/popup-add-staff.vue'))
window.PopupDispatchNotes = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Orders/popup-dispatch-notes.vue'))
window.PopupTransfer = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Routes/popup-transfer.vue'))
window.PopupDriverPayout = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Routes/popup-driver-payout.vue'))
window.PopupPayoutHistory = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Routes/popup-payout-history.vue'))
window.PopupRoutePayout = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Routes/popup-route-payout.vue'))
window.PopupNotesNew = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Notes/popup-new.vue'))
window.ApplyDriversView = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Apply/Drivers/driver-view.vue'))
window.UserAddressManager = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Users/address-manage.vue'))
window.PopupOrderEdit = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Orders/popup-order-edit.vue'))
window.PopupTicketsDetail = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Tickets/popup-detail.vue'))
window.PopupTicketsNew = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Tickets/popup-new.vue'))
window.PopupTicketsList = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Tickets/popup-list.vue'))
window.PopupTicketsTypeNew = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Tickets/Type/popup-create-new.vue'))
window.PopupOrderDetails = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Orders/popup-order-details.vue'))
window.PopupCallCenter = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Call/Center/popups/popup-call-center.vue'))
window.PopupMapRouteTest = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Map/popup-map-route-test.vue'))
window.PopupRouteServiceTime = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Routes/popup-route-service-time.vue'))
window.PopupRouteAssign = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/pages/Routes/popup-route-assign.vue'))
window.PopupReturnToPharmacy = defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/common/popups/popup-return-to-pharmacy.vue'))

const registryComponents = app => {
  ///* <vue2>
  app.component(JsonView.name, JsonView)
  app.component('v2-select', VueSelect)
  app.component('teleport', Teleport)
  // </vue2> */

  app.component('vue-select2', defineAsyncComponent(() => import(/* webpackChunkName: "out" */ '@/common/form/select2.vue')))

  app.component('clipboard', defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/common/comp/clipboard.vue')))
  app.component('modal-dialog', defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/common/modal-dialog.vue')))
  app.component('modal-window', defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/common/modal-window.vue')))

  // Helping components
  app.component('user-info', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/user-info.vue')))
  app.component('user-id', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/user-id.vue')))
  app.component('order-id', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/order-id.vue')))
  app.component('order-status', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/order-status.vue')))
  app.component('user-avatar', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/user-avatar.vue')))
  app.component('phone-call', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/phone-call.vue')))
  app.component('related-orders', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/related-orders.vue')))
  app.component('audio-record', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/audio-record.vue')))
  app.component('related-orders', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/related-orders.vue')))
  app.component('ticket-id', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/ticket-id.vue')))
  app.component('rx-loading', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/rx-loading.vue')))
  app.component('time-spend', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/comp/time-spend.vue')))

  // Form
  app.component('rx-select-input', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/elements/select.vue')))
  app.component('rx-switch-input', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/elements/switch.vue')))
  app.component('rx-number-input', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/elements/number.vue')))
  app.component('rx-text-input', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/elements/text.vue')))
  app.component('rx-checkbox-input', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/elements/checkbox.vue')))
  app.component('rx-autocomplete-input', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/elements/autocomplete.vue')))
  app.component('rx-form-input', defineAsyncComponent(() => import(/* webpackChunkName: "input" */ '@/common/rx-inputs/rx-form-input.vue')))
  app.component('rx-form-input-autocomplete', defineAsyncComponent(() => import(/* webpackChunkName: "input" */ '@/common/rx-inputs/rx-form-input-autocomplete.vue')))
  app.component('rx-form-input-select', defineAsyncComponent(() => import(/* webpackChunkName: "input" */ '@/common/rx-inputs/rx-form-input-select.vue')))
  app.component('rx-form-input-textarea', defineAsyncComponent(() => import(/* webpackChunkName: "input" */ '@/common/rx-inputs/rx-form-input-textarea.vue')))
  // Buttons
  app.component('rx-button', defineAsyncComponent(() => import(/* webpackChunkName: "input" */ '@/common/rx-buttons/rx-button.vue')))
  app.component('rx-buttons-group', defineAsyncComponent(() => import(/* webpackChunkName: "input" */ '@/common/rx-buttons/rx-buttons-group.vue')))

  app.component('rx-driver-route-selector', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/driver-route-selector.vue')))
  app.component('rx-swap-divider', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/form/swap-divider.vue')))

  app.component('panel-wrapper', defineAsyncComponent(() => import(/* webpackChunkName: "common" */ '@/common/panel-wrapper.vue')))

  ///* <vue2>
  app.component('custom-scrollbar', defineAsyncComponent(() => import('@/common/custom-scrollbar.vue')))
  // </vue2> */

  try {
    _.map(OrderEditComponents, async (v, k) => {
      app.component(k, v)
    })
    _.map(QaqcQueueComponents, async (v, k) => {
      app.component(k, v)
    })
    _.map(Table, async (v, k) => {
      app.component(k, v)
    })
  } catch (e) {

  }
}

export default registryComponents
